const Search = {
  init: function() {
    const container = document.querySelector('header .search');
    const buttonOpenFormModal = container.querySelector('[data-search]');
    const form = container.querySelector('form');
    const inputSearch = form.querySelector('[type=search]');
    const buttonCloseFormModal = form.querySelector('.close');
  
    function openSearchModal() {
      form.classList.add('active');
    }
    function closeSearchModal() {
      form.classList.remove('active');
    }
    function addEventCloseOnEsc() {
      window.addEventListener('keydown', function (event) {
        if (event.key == 'Escape') closeSearchModal();
      });
    }
    function removeEventCloseOnEsc() {
      window.removeEventListener('keydown', addEventCloseOnEsc);
    }
    buttonOpenFormModal.addEventListener('click', function () {
      openSearchModal();
      inputSearch.focus();
      addEventCloseOnEsc();
    });
    buttonCloseFormModal.addEventListener('click', function () {
      closeSearchModal();
      removeEventCloseOnEsc();
    });
  },
}

export default Search;
