import { addAsset } from "../components/utilities";

// ===============================================================
// NEWSLETTER POPUP
// ===============================================================
export const NewsletterComponent = {

  root: document.querySelector('#component-popup-newsletter-root'),
  settings: window.popupNewsletterSettings || false,
  loaded: false,

  setPopupNewsletter: function() {

    const { settings, root } = NewsletterComponent

    // Define propriedades do componente
    let maxWidth = settings.maxWidth;
    let imageUrl = settings.imageUrl;
    let title = settings.title;
    let description = settings.description;
    let subdomain = settings.subdomain;
    let successMessage = settings.success;

    // Inicia o componente
    const componentNewsletterPopup = new Vnda.Component.NewsletterPopup({
      maxWidth: maxWidth,
      maxHeight: 550,
      imageUrl: imageUrl,
      imagePosition: 'left',
      popupPosition: 'center',
      title: title,
      description: description,
      textBtnSubmit: 'Enviar',
      classBtnSubmit: 'btn-newsletter',
      formKey: `${subdomain}-newsletter`,
      hasNameField: true,
      hasLastNameField: true,
      hasDateOfBirthField: false,
      hasPhoneField: true,
      successMessage: successMessage,
      delay: 500,
      frequency: '7',
      language: 'pt-BR',
    });

    // Renderiza o componente e marca como carregado
    componentNewsletterPopup.render(root);
    NewsletterComponent.loaded = true
  },
  loadPopupNewsletter: function() {
    if (!NewsletterComponent.loaded) {
      const { settings } = NewsletterComponent;
      addAsset(settings.script, NewsletterComponent.setPopupNewsletter)
      addAsset(settings.styles)
    }
  },
  init: function() {
    const { root, settings } = this;

    if (!root || !settings) return;

    const eventType = (window.innerWidth <= 1024) ? 'scroll' : 'mousemove'
    window.addEventListener(eventType, () => {
      NewsletterComponent.loadPopupNewsletter()
    }, { once: true })
  }
}

// ===============================================================
// FILTROS
// ===============================================================
export function setFilters() {
  let properties = [];

  window.addEventListener('load', () => {
    // Seleciona o elemento
    const root = document.querySelector('#component-products-filter-root');

    // Define filtros de categoria
    let tags = aggregations.types.categoria && [
      {
        title: 'Categoria',
        type: 'categoria',
        style: 'grid',
        gridProps: {
          columns: 2,
        },
        options: aggregations.types.categoria,
      },
    ];

      // Ordena tamanhos
      const sizesOrder = ['pp', 'p', 'm', 'g', 'gg', 'xg'];
      const sizes = aggregations.properties.property1;
      const newSizes = sizes.sort((optionA, optionB) => {
        return (
          sizesOrder.indexOf(optionA.value.toLowerCase()) -
          sizesOrder.indexOf(optionB.value.toLowerCase())
        );
      });
  
      // Define filtros do primeiro atributo
      let property1 = aggregations.properties.property1.length > 0 && {
        title: 'Tamanho',
        property: 'property1',
        style: 'list',
        options: newSizes,
      };
  
      if (property1) {
        properties.push(property1);
      }

    // Define filtros do segundo atributo
    let property2 = aggregations.properties.property2.length > 0 && {
      title: 'Detalhe',
      property: 'property2',
      style: 'list',
      options: aggregations.properties.property2,
    };

    if (property2) {
      properties.push(property2);
    }

  

    // Define filtros do terceiro atributo
    let property3 = aggregations.properties.property3.length > 0 && {
      title: 'Tecido',
      property: 'property3',
      style: 'grid',
      gridProps: {
        columns: 4,
      },
      options: aggregations.properties.property3,
    };

    if (property3) {
      properties.push(property3);
    }

    // Inicia o componente
    const componentFilters = new Vnda.Component.ProductsFilter({
      mode: 'drawer',
      hasSort: true,
      filterOnClick: false,
      resetMode: 'all',
      // tags type "categoria"
      tags: tags,
      // properties
      properties: properties,
      // price
      price: [aggregations.min_price, aggregations.max_price],
    });

    // Renderiza o componente
    componentFilters.render(root);

    // Adiciona evento de abertura
    let open = document.querySelector('.open-filter');
    if (open) open.addEventListener('click', componentFilters.toggle);
  });
}

// ===============================================================
// LOGIN
// ===============================================================
export function setLogin() {
  window.addEventListener('load', () => {
    // Seleciona o elemento
    const root = document.querySelector('#component-login-static-root');

    // Define link para o Facebook
    let facebook_connect_url = root.getAttribute('data-facebook');

    // Inicia o componente
    window.componentLogin = {};
    window.componentLogin['static'] = new Vnda.Component.Login({
      mode: 'static',
      formProps: {
        login: {
          buttons: {
            facebook: {
              link: facebook_connect_url,
            },
          },
          hasFacebook: false,
        },
        register: {
          buttons: {
            facebook: {
              link: facebook_connect_url,
            },
          },
          hasFacebook: false,
        },
      },
    });

    // Renderiza o componente
    componentLogin['static'].render(root);
  });
}

// ===============================================================
// PREÇO
// ===============================================================
// Carrega o componente de preço quando um product-block ou product-infos entra em tela
export const PriceComponent = {
  script: window.priceComponent || false,
  loaded: false,
  init: function() {
    if (!PriceComponent.script) return;
    const productContainers = document.querySelectorAll('[data-product-box]');
    if (productContainers.length === 0) return;
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (!PriceComponent.loaded) {
          addAsset(PriceComponent.script)
          PriceComponent.loaded = true
        }        
        observer.disconnect()
      }
    }, { threshold: 0.1 });
    productContainers.forEach(product => {
      observer.observe(product)
    })
  }
}

